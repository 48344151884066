.Footer {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 100px;
    margin-bottom: 80px;

    a {
        text-decoration: underline;
    }
}

.footerContainer {
    display: flex;
    flex-direction: row;

    font-size: 14px;

    a {
        text-decoration: none;
    }

    .left {
        max-width: 300px;
    }

    .right {
        max-width: 400px;
        width: 100%;

        div {
            display: flex;
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 800px) {
    .footerContainer {
        flex-direction: column;
        gap: 20px;
    }
}
