@use "../../variables" as v;

.container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: center;

    .image {
        object-fit: cover;
        height: 100%;
    }
}

.container::-webkit-scrollbar {
    display: none;
}

.arrow {
    position: absolute;
    color: v.$primary;
    background-color: #fff;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;

    &.hidden {
        opacity: 0;
        cursor: default;
        pointer-events: none;
    }

    &.bounce {
        -moz-animation: bounce 3s infinite;
        -webkit-animation: bounce 3s infinite;
        animation: bounce 3s infinite;
    }
}

.left {
    left: 50px;
}

.right {
    right: 50px;
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateX(0);
        transform: translateX(0);
    }
    40% {
        -moz-transform: translateX(-30px);
        transform: translateX(-30px);
    }
    60% {
        -moz-transform: translateX(-15px);
        transform: translateX(-15px);
    }
}
@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    40% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
    60% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px);
    }
}
@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    40% {
        -moz-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
    60% {
        -moz-transform: translateX(-15px);
        -ms-transform: translateX(-15px);
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px);
    }
}

