.container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    position: relative;
    margin-bottom: 60px;
}

.dots1 {
    position: absolute;
    right: -10%;
    top: 0;
    z-index: -1;
}

.dots2 {
    position: absolute;
    left: -10%;
    bottom: 0;
    z-index: -1;
}
