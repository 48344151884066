@use "../../variables" as v;

.parallax1 {
    // background-image: url("../../assets/img/parallax1.png");
    background-color: v.$primary;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
}

.parallax1Buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.videoIFrame {
    max-width: 560px;
    height: 315px;
    width: 100%;
    border-radius: 20px;
    position: absolute;
    right: 5%;
    top: -35px;
}

@media (max-width: 600px) {
    .parallax1Buttons {
        flex-direction: column;
        width: 100%;

        button {
            width: 100%;
        }
    }
}

@media(max-width: 1350px) {
    .videoIFrame {
        position: relative;
        top: 0;
        right: 0;
        margin-top: 20px;
    }
}