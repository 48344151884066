.map {
    width: 100% !important;
    position: relative;

    .mapCard {
        position: relative;
        text-align: left;
        width: 100%;
        max-width: 350px;
        margin: 0px auto;
        margin-top: 50px;
        margin-bottom: 0px;
    }
}

.mapContainer {
    height: 500px;
    width: 100%;
}

.directionsIcon {
    position: absolute;
    right: -22px;
    top: -22px;
    height: 72px;
    width: 72px;
}

.contact {
    margin-top: 50px;
    margin-bottom: 60px;
    display: flex;

    .contactLeft {
        flex: 1;
        max-width: 600px;
        margin-right: 40px;
    }

    .contactRight {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
}

.graphicImage {
    height: 100%;
    max-width: 400px;
    border-top-right-radius: 100px;
}

.contactCard {
    position: absolute;
    left: 50px;
    bottom: 10px;
}

@media (min-width: 800px) {
    .map {
        .mapCard {
            position: absolute;
            top: 15%;
            left: 10%;
        }
    }
}

@media (max-width: 800px) {
    .contact {
        flex-direction: column;
        flex-flow: column-reverse;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .contact {
        .contactLeft {
            margin-right: 0px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .map {
        .mapCard {
            width: 80%
        }
    }

    .contactCard {
        position: relative;
        left: unset;
    }

    .graphicImage {
        display: none;
    }
}
