.HelperText {
    animation: pulse 4s ease-in-out infinite;
}

@keyframes pulse {
    0% {
     transform: scale(1, 1);
    }

    35% {
     transform: scale(1.1, 1.1);
    }

    45% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    60% {
        transform: scale(1, 1);
    }

    100% {
    transform: scale(1, 1);
    }
}