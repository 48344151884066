.Image {
    width: 400px;
    max-width: 90%;
    border-radius: 20px;
}

.Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
