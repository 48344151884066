.container {
    width: 100% !important;
    text-align: center;
    margin-top: 20px;
}

.topBar {
    padding-top: 24px;
    padding-bottom: 24px;
}
