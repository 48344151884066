.container {
    margin-top: 60px;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: 1em;
    column-gap: 1em;
}

@media (max-width: 600px) {
    .container {
        margin-top: 40px;
    }
}