.pinIconContainer {
    transform: translate(-25%, 0%);
    transition: 0.3s;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pinIconCircle1 {
    background-color: #49615733;
    height: 180px;
    width: 180px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.pinIconCircle2 {
    background-color: #49615740;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
}
