@use "../../variables" as v;

.container {
    display: flex;
    align-items: center;
}

.iconCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: v.$primary;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    margin-right: 12px;
    color: #FFFFFF;
    transition: 0.5s all;
}

.iconCircle:active {
    scale: 1.1;
}
