body {
  min-height: 100vh;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:active {
  -webkit-tap-highlight-color: transparent;
}

h1 {
  font-size: 40px;
  font-weight: 600;
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 600;
}

h4 {
  font-size: 18;
  font-weight: 600;
}

p {
  color: #646464;
  margin: 1em 0px;
}

a {
  color: #121212B2;
  text-decoration: none;
}

hr {
  height: 1px;
  background-color: #B9B7D1;
  border: none;
}

.muted {
  font-size: 14px;
  font-weight: 400;
  color: #12121280;
}

.bold {
  font-weight: bold;
  color: #121212;
}

section.inverted,
div.inverted {
  background-color: #496157;
  color: #fff;
}

section.inverted {
  width: 100% !important;
}

.inverted a {
  color: #FFFFFF
}

.inverted p {
  color: #FFFFFF
}