@use "../../variables" as v;

.services {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.aboutService {
    display: flex;
    margin-top: 100px;
    gap: 60px;

    p {
        color: v.$text-black;
    }
}

.aboutServiceInfo {
    flex: 1;
}

.aboutServiceImage {
    width: 350px;
    height: 400px;
    object-fit: cover;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
}

.aboutServiceCustom {
    color: v.$text-black;

    h2 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.faq {
    margin-top: 20px;
}

@media (max-width: 1000px) {
    .aboutService {
        flex-direction: column;
        align-items: center;
    }
}
