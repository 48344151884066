.card {
    h2 {
        font-weight: 500;
        white-space: break-spaces;
    }

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s all;
    aspect-ratio: 1;
}

.card.large {
    height: 250px;

    .iconContainer {
        height: 96px;
    }

    .iconCircle {
        height: 60px;
        width: 60px;
    }
}

.card.small {
    height: 150px;

    h2 {
        font-size: 18px;
    }

    .iconContainer {
        height: 62px;
    }

    .icon {
        height: 62px;
    }

    .iconCircle {
        height: 40px;
        width: 40px;
        top: -5px;
        left: -10px;
    }
}

.card.selected, .card:hover {
    background-color: #4961571a;
    border-radius: 10px;
    filter: drop-shadow(5px 15px 25px rgba(0, 0, 0, 0.12));
    cursor: pointer;
}

.iconContainer {
    margin-bottom: 10px;
}

.iconCircle {
    background-color: #4961570f;
    position: absolute;
    top: -15px;
    left: -15px;
    border-radius: 50%;
}

@media (max-width: 1000px) {
    .card {
        width: 45%
    }

    .card.large {
        height: 150px;
    
        h2 {
            font-size: 18px;
        }
    
        .iconContainer {
            height: 62px;
        }
    
        .icon {
            height: 62px;
        }
    
        .iconCircle {
            height: 40px;
            width: 40px;
            top: -5px;
            left: -10px;
        }
    }
}