.container {
    display: grid;
    grid-template-areas:
        "book-appointment"
        "hours"
        "emergency";
    padding-top: 60px;
    padding-bottom: 40px;
    padding-left: 7.5%;
    padding-right: 7.5%;

    p {
        color: #fff;
    }
}

.bookAppointment {
    grid-area: book-appointment;
    margin-bottom: 30px;
}

.hours {
    grid-area: hours;
    margin-bottom: 30px;
}

.emergency {
    grid-area: emergency;
}

@media (min-width: 800px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "book-appointment hours emergency";
        column-gap: 5%;
    }
}
