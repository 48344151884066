@use "../../../variables" as v;

.ulNav {
    li {
        margin-bottom: 10px;
        cursor: pointer;

        a {
            color: black;
            text-decoration: none;
            padding: 5px 25px;
            color: v.$text-tertiary;
            font-weight: 500;
        }

        a.active {
            color: v.$text-primary;
        }
    }
}
