@use "../../../variables" as v;

.container {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    gap: 150px;

    p {
        margin: 1.5em 0px;
    }
}

.graphicText {
    flex: 1;
    margin-bottom: 10%;
}

.graphic {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.graphicImage {
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    object-fit: cover;
    object-position: 5px 0;
    width: 100%;
}

.iconCircle {
    position: absolute;
    background-color: v.$primary;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8em;
    width: 8em;
    border-radius: 50%;
    top: 30px;
    left: 5px;

    img {
        height: 60%;
    }
}

@media (max-width: 1100px) {
    .container {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 600px) {
    .iconCircle {
        top: 0px;
        left: 0px;
        height: 6em;
        width: 6em;
    }
}
