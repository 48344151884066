.Image {
    max-width: 50%;
    max-height: 800px;
}

.Container {
    display: flex;
}

@media (max-width: 600px) {
    .Container {
        flex-direction: column !important;
    }

    .Image {
        max-width: 95%;
        border-radius: 20px !important;
    }
}