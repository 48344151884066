@use "../../variables" as v;

$header-height: 110px;
$footer-height: 350px;

.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.header {
    grid-area: header;
    width: 100%;
    height: 110px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 7.5%;
    padding-left: 5%;
    z-index: 2;
    transition: 0.5s;
    cursor: pointer;
}

.logo {
    width: 200px;
    max-height: 100px;
}

.nav {
    li {
        list-style-type: none;
        display: inline;
        height: 24px;
    }
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    scroll-behavior: smooth;

    section {
        width: 80%;
        align-self: center;
    }
}

.hamburger {
    display: none;
}

.hamburgerNav {
    display: none;
    background-color: #fff;
    position: absolute;
    top: -100%;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
    z-index: 1;
    transition: 0.5s;
}

@media (max-width: 950px) {
    .nav {
        display: none;
    }

    .header.hideShadow {
        box-shadow: 0px 0px 0px transparent;
    }

    .hamburger {
        display: flex;
    }

    .hamburgerNav {
        display: block;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        ul {
            list-style-type: none;
        }
    }

    .hamburgerNav.open {
        top: 110px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
