@use "../../../variables" as v;

.container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 60px;
    max-width: 1250px;
    display: flex;
    flex-direction: row;
    gap: 150px;
    justify-content: center;

    p {
        margin: 1.5em 0px;
    }
}

.headlineContainer {
    display: flex;
    flex-direction: column;
}

.headlineTextImage {
    width: 90%;
    min-height: 70px;
}

.graphic {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.graphicImage {
    height: 600px;
    max-width: 534px;
    object-fit:cover;
    border-top-right-radius: 100px;
    object-position: right;
}

.graphicCircle {
    width: 65vh;
    height: 65vh;
    border-radius: 50%;
    background-color: #428b460f;
    position: absolute;
    text-align: center;
    z-index: -1;
}

.graphicText {
    grid-area: text;
    margin-top: auto;
    margin-bottom: auto;
}

.card {
    position: absolute;
    margin-top: 20%;
    left: -100px;
}

@media (max-width: 1350px) {
    .container {
        flex-direction: column;
        gap: 20px;
    }

    .graphic {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    
    .card {
        bottom: -100px;
        left: unset;
    }

    .graphicImage {
        height: 400px;
        max-width: 400px;
    }
}

@media (max-width: 600px) {
    .graphicImage {
        width: 100%;
    }

    .card {
        bottom: -50%;
    }

    .container {
        margin-bottom: 175px;
    }
}
