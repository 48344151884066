.Card {
    width: 250px;
    background: #ffffff;
    box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 15px 2.5%;
}
